import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useInterval } from 'utils/utils';

import { SvgLib } from 'components/components';

const SearchForm = ({
  onSubmit = undefined,
  searchValue = false,
  placeholderArray = ['Typing...'],
  showLabel = false,
  hideSubmit = false,
}) => {
  const { register, handleSubmit, errors, setValue } = useForm();

  const [isRunning, setIsRunning] = useState(true);

  const [placeholder, setPlaceholder] = useState('');

  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [placeholderCurrent, setPlaceholderCurrent] = useState(0);
  const [placeholderReverse, setPlaceholderReverse] = useState(false);

  useInterval(
    () => {
      if (
        placeholderCurrent > placeholderArray[placeholderIndex].length &&
        !placeholderArray[placeholderIndex + 1]
      ) {
        setIsRunning(false);
        return false;
      } else if (
        placeholderCurrent > placeholderArray[placeholderIndex].length
      ) {
        setPlaceholderReverse(true);
      } else if (placeholderCurrent < 0 && placeholderReverse) {
        setPlaceholderReverse(false);
        setPlaceholderIndex(placeholderIndex + 1);
      }

      const text = placeholderArray[placeholderIndex].substring(
        0,
        placeholderCurrent
      );

      setPlaceholderCurrent(
        placeholderReverse ? placeholderCurrent - 1 : placeholderCurrent + 1
      );

      setPlaceholder(text);
    },
    isRunning ? 100 : null
  );

  useEffect(() => {
    if (searchValue) {
      setValue('search', searchValue);
    }
  }, [searchValue, setValue]);

  const onFocusSet = (data) => {
    if (isRunning) {
      setIsRunning(false);
      setPlaceholder(placeholderArray[placeholderArray.length - 1]);
    }
  };

  return (
    <form className="SearchForm" onSubmit={handleSubmit(onSubmit)}>
      <div
        className={
          'SearchForm__input SearchForm__input--main' +
          (showLabel ? ' has-label' : '') +
          (errors.search ? ' has-error' : '')
        }
      >
        {showLabel && (
          <label htmlFor={'search'}>
            <SvgLib svg="search" />
          </label>
        )}

        <input
          name={'search'}
          type={'text'}
          placeholder={placeholder}
          onFocus={onFocusSet}
          ref={register({ required: true })}
        />
      </div>
      <div
        className={'SearchForm__button' + (hideSubmit ? ' hide-submit' : '')}
      >
        <button className="Button Button--search" type="submit">
          Search
        </button>
      </div>
    </form>
  );
};

export default SearchForm;
