import moment from 'moment';

export function filterTracking(tracking) {
  let returnArray =
    tracking?.split(', ')?.map((item) => {
      const itemArray = item.split('_');
      return {
        date: itemArray[0],
        total: itemArray[1],
      };
    }) ?? [];
  return returnArray;
}

/**
 * API
 */

export function averageChartPie(array, active) {
  if (!Array.isArray(array)) return false;

  // console.log(array);

  let data = [];
  let totals = [];

  // add all nr
  for (let i = 0; i < array.length; i++) {
    const el = array[i];
    const track = active ? el.trackingFiltered : el.tracking;
    let total = 0;
    for (let i = 0; i < track.length; i++) {
      total = total + parseInt(track[i].total);
    }
    totals[i] = total;
  }

  // total of all
  var sumTotal = totals.reduce(function (a, b) {
    return a + b;
  }, 0);

  // final data
  for (let iIndex = 0; iIndex < array.length; iIndex++) {
    const el = array[iIndex];
    const totalPerc = (100 * totals[iIndex]) / sumTotal;
    const perc = Math.round(totalPerc);

    data[iIndex] = {
      label: el.title,
      value: perc,
      total: totals[iIndex],
      className: 'labelChartPie--' + iIndex,
      indexVal: iIndex + 1,
    };
  }

  return data;
}

export function chartPieValues(array) {
  if (!Array.isArray(array)) return false;

  let data = [];
  let total = 0;

  // total of all
  for (let i = 0; i < array.length; i++) {
    const el = array[i];
    total = total + parseInt(el.value);
  }

  // final data
  for (let i = 0; i < array.length; i++) {
    const el = array[i];

    const totalPerc = (100 * parseInt(el.value)) / total;
    const perc = Math.round(totalPerc);

    data[i] = {
      label: el.label,
      value: perc,
      total: el.value,
      className: 'labelChartPie--' + i,
      indexVal: i + 1,
    };
  }

  return data;
}

export function getSearchApi(index) {
  let apiKey = 'AIzaSyDYqG6DbjBuTvoLc1igo0pk5PceQlOXku0';
  let searchCX = '000097836099721828130:w_nedaecymk';

  if (index === 2) {
    apiKey = 'AIzaSyBeHkC775JaTmiWIN07jqa8oM_IT8dsN0k';
  } else if (index === 3) {
    apiKey = 'AIzaSyAZVyNYiHlcfPsSfIrd-zifqEB5JEyQB1c';
  } else if (index === 4) {
    apiKey = 'AIzaSyBxj7P-PXljucPW1Q3Grl9xx6qdBUSyXaE';
  } else if (index === 5) {
    apiKey = 'AIzaSyAu9zT60H_d_BrCC2bKjlcHGIk-esfL4b8';
    searchCX = '90e93c9222009c64f';
  } else if (index === 6) {
    apiKey = 'AIzaSyA39uedoWGQ_OKnn849WwbCx6spymEq6lU';
  } else if (index === 7) {
    apiKey = 'AIzaSyDHXOuG4psfaVtZOjZqgelM0cIj6xdg_y0';
  } else if (index === 8) {
    apiKey = 'AIzaSyD6_bsZqmB5i0oUobd7pe9XtRsq6h-eBlM';
  } else if (index === 9) {
    apiKey = 'AIzaSyBSTHUzSDtjj1MRiK7btqm3QQUAlT0jgU8';
  } else if (index === 10) {
    apiKey = 'AIzaSyCy2toWcBLmThFJtzCD_tDejP-wbT4CUtQ';
  } else if (index === 11) {
    apiKey = 'AIzaSyDnWhzQ8zpKlXMStRsMOLyUcYwjos8AmE0';
  } else if (index === 12) {
    apiKey = 'AIzaSyDQRXlggL6vSce_re4HazqK79t9E89uxY8';
  } else if (index === 13) {
    apiKey = 'AIzaSyADzsiXY8IqUXtuaV7-e9YQy3g30VlCVjE';
  } else if (index === 15) {
    apiKey = 'AIzaSyDM4492i8HHaEvyjjtCxj4Xr1UxijCRPpc';
  }

  const apiText =
    'https://www.googleapis.com/customsearch/v1/siterestrict?cx=' +
    searchCX +
    '&key=' +
    apiKey +
    '&imgSize=medium';
  const apiImage =
    'https://www.googleapis.com/customsearch/v1/siterestrict?cx=' +
    searchCX +
    '&key=' +
    apiKey +
    '&imgSize=medium' +
    '&searchType=image';
  return {
    apiText,
    apiImage,
  };
}

/**
 * Merge Words
 */
export function mergeWords(section, words) {
  if (!Array.isArray(section) && !Array.isArray(words)) return [];
  let data = [];

  if (words.length) {
    for (let wordsI = 0; wordsI < section.length; wordsI++) {
      const word = section[wordsI];
      const hasWord = words ? words.filter((x) => x.id === word) : [];
      if (hasWord.length) {
        data[wordsI] = hasWord[0];
      }
    }
  }

  return data;
}

/**
 * Set Page
 */

/**
 * Get Items by ID or Slug
 */
export function getItems(ids, array) {
  if (!ids || !Array.isArray(array)) return false;
  let items = [];

  for (let i = 0; i < array.length; i++) {
    const el = array[i];

    if (ids.includes(el.id) || ids.includes(el.slug)) {
      items.push(el);
    }
  }

  return items;
}

/**
 * Get Item by ID or Slug
 */
export function getItem(id, array) {
  if (!id || !Array.isArray(array)) return false;

  const items = array.filter((item) => item.slug === id);

  if (!items) {
    return false;
  }

  return items[0];
}

/**
 * Get Item by ID or Slug
 */
export function getCurrentActive(id, array) {
  if (!id || !Array.isArray(array)) return false;
  let item = false;

  for (let i = 0; i < array.length; i++) {
    const el = array[i];

    if (id === el.slug || id === el.id) {
      item = i;
    }
  }

  return item;
}

export function formatNr(t) {
  const nr = t.toString();
  const total = nr.length;
  const number =
    total > 6 ? nr.slice(0, -6) + 'm' : total > 3 ? nr.slice(0, -3) + 'k' : nr;
  return number;
}

/**
 * order items
 */

export function orderItems(array) {
  if (!Array.isArray(array)) return false;
  let items = [];

  for (let i = 0; i < array.length; i++) {
    const el = array[i];
    // let words = [];
    items[i] = { title: el.title, words: [] };

    // for (let iSub = 0; iSub < el.sub_sections.length; iSub++) {
    //   const elSub = el.sub_sections[iSub];

    //   for (let iSubTwo = 0; iSubTwo < elSub.words.length; iSubTwo++) {
    //     const element = elSub.words[iSubTwo];
    //     words.push(element);
    //   }
    // }

    // // sort by name
    // words.sort(function(a, b) {
    //   var nameA = a.word.toUpperCase(); // ignore upper and lowercase
    //   var nameB = b.word.toUpperCase(); // ignore upper and lowercase
    //   if (nameA < nameB) {
    //     return -1;
    //   }
    //   if (nameA > nameB) {
    //     return 1;
    //   }

    //   // names must be equal
    //   return 0;
    // });
    // items[i]['words'] = words;
  }

  return items;
}
export function orderItemsFilter(array) {
  if (!Array.isArray(array)) return false;
  let items = [];

  // console.log(array);

  for (let i = 0; i < array.length; i++) {
    const el = array[i];
    let words = [];
    items[i] = { title: el.title, words: [] };

    console.log(el.sub_sections);

    // console.log('tesssst', el);
    for (let iSub = 0; iSub < el.sub_sections.length; iSub++) {
      console.log('test');
      // const elSub = el.sub_sections[iSub];

      // console.log('test', elSub);

      // for (let iSubTwo = 0; iSubTwo < elSub.words.length; iSubTwo++) {
      //   const element = elSub.words[iSubTwo];
      //   words.push(element);
      // }
    }
    // }

    //   // sort by name
    //   words.sort(function(a, b) {
    //     var nameA = a.word.toUpperCase(); // ignore upper and lowercase
    //     var nameB = b.word.toUpperCase(); // ignore upper and lowercase
    //     if (nameA < nameB) {
    //       return -1;
    //     }
    //     if (nameA > nameB) {
    //       return 1;
    //     }

    //     // names must be equal
    //     return 0;
    //   });
    items[i]['words'] = words;
  }

  return items;
}

export function filterWords(array, active) {
  let arrayMain =
    array?.map((item) => ({
      ...item,
      tracking: filterTracking(item.tracking),
    })) ?? [];

  if (!active) return arrayMain;

  let data = [];

  for (let index = 0; index < arrayMain.length; index++) {
    let item = arrayMain[index];
    let track = [];

    for (let i = 0; i < arrayMain[index]['tracking'].length; i++) {
      const el = arrayMain[index]['tracking'][i];
      if (moment(el.date).isBetween(active[0], active[1])) {
        track.push(el);
      } else if (moment(el.date).isSame(active[0])) {
        track.push(el);
      }
    }

    data.push(item);
    data[index]['trackingFiltered'] = track;
  }

  return data;
}
