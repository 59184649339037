import React, { useRef, useEffect } from 'react';
import { Item, Button } from 'components/components';
import Packery from 'packery';
import { slugify } from 'utils/utils';

import { useInView } from 'react-intersection-observer';

import { useTransition, animated } from 'react-spring';

const SearchItems = ({
  type = false,
  items = [],
  info = [],
  isLoading = false,
  loadMore = false,
  searchValue = false,
}) => {
  const total = info.formattedTotalResults ? info.formattedTotalResults : false;

  const refGrid = useRef(false);

  const slug = slugify(searchValue);

  useEffect(() => {
    let pack = false;

    if (pack) {
      pack.destroy();
    }
    if (refGrid && !pack) {
      pack = new Packery(refGrid.current, {
        gutter: 0,
        percentPosition: true,
      });
    }
  }, [refGrid, items]);

  const [inViewRef, inView] = useInView({
    rootMargin: '200px 0px',
  });

  const transitions = useTransition(items, (item) => item.key, {
    from: { transform: 'translate3d(0,-40px,0)', opacity: 0 },
    enter: { transform: 'translate3d(0,0px,0)', opacity: 1 },
    leave: { transform: 'translate3d(0,-40px,0)', opacity: 0 },
  });

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [inView, loadMore]);

  return (
    <div className={'SearchItems' + (type ? ' SearchItems--' + type : '')}>
      <div className={'SearchItems__head'}>
        {total && (
          <h2>
            {type}: {total} Results
          </h2>
        )}
      </div>
      <div className={'SearchItems__inner'}>
        <div className="SearchItems__grid" ref={refGrid}>
          {transitions.map((props, i) => (
            <animated.div
              style={props}
              className="SearchItems__item"
              key={'serachItems--' + slug + i}
            >
              <Item {...items[i]} type={type} />
            </animated.div>
          ))}
        </div>

        <div className={'SearchItems__loadmore'} ref={inViewRef}>
          {isLoading ? (
            <Button theme="loading" />
          ) : (
            <div />
            // <Button text="Load more" onClick={loadMore} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchItems;
