export function itemsSlugs(array) {
  if (!array) return false;

  let slugs = '';

  for (let i = 0; i < array.length; i++) {
    const el = array[i];

    if (el.word) {
      slugs = i === 0 ? slugs + el.slug : slugs + '-' + el.slug;
    }
  }

  return slugs;
}

/**
 * Merge an array of classNames into a single string
 *
 * @param {array} classNames
 * @returns {string}
 */
export function mergeClassNames(...classNames) {
  const mergedClassNames = [];

  classNames.forEach((className) => {
    if (!className || className === '') return;
    mergedClassNames.push(className);
  });

  return mergedClassNames.join(' ');
}

/**
 * Add a reference to an array
 *
 * @param {element} el
 * @param {array} array
 * @returns {undefined}
 */
export function refToArray(el, array) {
  if (!el || !Array.isArray(array) || array.includes(el)) return;
  return array.push(el);
}

/**
 * Bind functions
 *
 * @param {this} self
 * @param {array} functions
 * @returns {string}
 */
export function bind(self, ...functions) {
  functions.forEach((name) => {
    self[name] = self[name].bind(self);
  });
}

/**
 * Captilize the first letter of a string
 *
 * @param {string} string
 * @returns {string}
 */
export function capitalize(string) {
  if (string.length === 0) return '';
  return string[0].toLocaleUpperCase() + string.substr(1);
}

/**
 * Generate a random string
 *
 * @param {number} characters of characters in the string
 * @returns {string} random string
 */
export function randomString(characters = 5) {
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let rndmStr = '';

  for (let i = 0; i < characters; i++)
    rndmStr += possible.charAt(Math.floor(Math.random() * possible.length));

  return rndmStr;
}

//
export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}
