import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getSearchApi } from 'utils/utils';
import {
  CompareForm,
  ChartPie,
  Button,
  useNotification,
} from 'components/components';
// import { apiurl } from 'options';

import dataRecentCompare from '../../data/recentcompare.json';

const Compare = ({ isMobile = false }) => {
  const showNotification = useNotification();

  const [recentCompared, setRecentCompared] = useState(
    dataRecentCompare?.recentcompare || []
  );
  const [noWords, setNoWords] = useState([]);
  const [ignore, setIgnore] = useState(false);

  const [errorApp, setErrorApp] = useState(false);

  // useEffect(() => {
  //   if (!ignore) {
  //     axios
  //       .get(`${apiurl}recentcompare`)
  //       .then(function (response) {
  //         // console.log(response);
  //         setRecentCompared(response.data['recentcompare']);
  //         setNoWords(response.data['nowords'].split(', '));
  //       })
  //       .catch(function (error) {
  //         // handle error
  //         // console.log(error);
  //       });

  //     setIgnore(true);
  //   }
  // }, [ignore]);

  //

  const [apiIndex, setApiIndex] = useState(0);
  const { apiText } = getSearchApi(apiIndex);

  const [compareItemOne, setCompareItemOne] = useState(false);
  const [compareItemTwo, setCompareItemTwo] = useState(false);

  const onSubmit = (data) => {
    const checkNoWord = noWords.includes(data.compareOne.toLowerCase());
    const checkNoWordTwo = noWords.includes(data.compareTwo.toLowerCase());
    if (checkNoWord || checkNoWordTwo) return false;

    addToRecentCompare(data.compareOne, data.compareTwo);
    makeCompare(data.compareOne, data.compareTwo);
  };

  const makeCompare = (value, valueTwo) => {
    // const requestOne = axios.get(apiText + '&q=' + value);
    // const requestTwo = axios.get(apiText + '&q=' + valueTwo);

    // setCompareItemOne([value, false]);
    // setCompareItemTwo([valueTwo, false]);

    showNotification('Compare is disabled. Website has been discontinued.');

    return;

    // axios
    //   .all([requestOne, requestTwo])
    //   .then(
    //     axios.spread((...responses) => {
    //       const responseOne = responses[0];
    //       const responseTwo = responses[1];

    //       setCompareItemOne([
    //         value,
    //         responseOne.data.searchInformation.totalResults,
    //       ]);
    //       setCompareItemTwo([
    //         valueTwo,
    //         responseTwo.data.searchInformation.totalResults,
    //       ]);

    //       // use/access the results
    //       // console.log(responseOne, responseTwo);
    //     })
    //   )
    //   .catch((errors) => {
    //     // react on errors.
    //     setApiIndex(apiIndex + 1);
    //     // console.log(apiIndex);
    //     // makeCompare(value, valueTwo, $recent);
    //     // console.warn('errors:', errors);
    //   });
  };

  const addToRecentCompare = (value, valueTwo) => {
    return;
    // setRecentCompared((recentCompared) => [
    //   {
    //     word: value,
    //     word_two: valueTwo,
    //   },
    //   ...recentCompared,
    // ]);

    // var searchData = new FormData();
    // searchData.append('word', value);
    // searchData.append('word_two', valueTwo);

    // var config = {
    //   method: 'post',
    //   url: `${apiurl}compare`,
    //   headers: {
    //     Authorization: 'Basic b29ydDpuNnREIHBPSmUgTHdDWSBBNWh6IFFwZ2UgZUNqcw==',
    //   },
    //   data: searchData,
    // };

    // axios(config)
    //   .then(function (response) {
    //     // console.log(JSON.stringify(response.data));
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  };

  const clearSearch = (data) => {
    setCompareItemOne(false);
    setCompareItemTwo(false);
    //input
    if (document.querySelector('#compareOne')) {
      document.querySelector('#compareOne').value = '';
    }
    if (document.querySelector('#compareTwo')) {
      document.querySelector('#compareTwo').value = '';
    }
  };

  // effect
  useEffect(() => {
    // if (compareItemOne[0] && compareItemTwo[0] && apiIndex !== 16) {
    //   // makeCompare(compareItemOne[0], compareItemTwo[0], true);
    // }
    if (apiIndex === 16) {
      setErrorApp('Daily limit exceeded');
    }
  }, [apiIndex]);

  //show
  const showCompare = compareItemOne[1] && compareItemTwo[1] ? true : false;

  return (
    <div className="Compare">
      <div className="Search__clear" onClick={(e) => clearSearch()} />

      <div className={'Compare__inner'}>
        <div className={'Compare__search'}>
          <CompareForm onSubmit={onSubmit} />
          {errorApp && <div className="errorApp">{errorApp}</div>}
        </div>
        <div className="Compare__chart">
          {showCompare && (
            <ChartPie
              compareChart={true}
              data={[
                { value: compareItemOne[1], label: compareItemOne[0] },
                { value: compareItemTwo[1], label: compareItemTwo[0] },
              ]}
            />
          )}
        </div>
      </div>

      {!compareItemOne && recentCompared ? (
        <div className="Search__recent">
          <h2>Recently compared:</h2>
          <ul>
            {recentCompared.map(
              (item, i) =>
                i <= (isMobile ? 3 : 20) && (
                  <li key={'recentSearch--' + i}>
                    <Button
                      onClick={(e) => {
                        //input
                        if (document.querySelector('#compareOne')) {
                          document.querySelector('#compareOne').value =
                            item.word;
                        }
                        if (document.querySelector('#compareTwo')) {
                          document.querySelector('#compareTwo').value =
                            item.word_two;
                        }
                      }}
                      to="/"
                      text={item.word + ' vs. ' + item.word_two}
                    />
                  </li>
                )
            )}
          </ul>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Compare;
