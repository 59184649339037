import React from 'react';

const TextSlider = ({ text = false, className = '' }) => {
  return (
    <div className={'TextSlider ' + className}>
      <div className={'TextSlider__main'}>
        <span>{text}</span>
        <div className={'TextSlider__copy'}>
          <span>{text}</span>
          <span>{text}</span>
          <span>{text}</span>
        </div>
      </div>
    </div>
  );
};

export default TextSlider;
