import React, { useEffect, useState } from 'react';
import { ExploreFilter, ExploreItem } from 'components/components';
// import useSWR from 'swr';
import axios from 'axios';
import useMeasure from 'react-use-measure';
import { itemsSlugs } from 'utils/utils';
import {
  // apiurl,
  apiurlpublic,
} from 'options';
import dataExploreJson from '../../data/explore.json';

// const fetcher = (url) => axios.get(url).then((res) => res.data);

const ExploreSection = ({
  index = 0,
  sub_sections = [],
  uid = '',
  isMobile = false,
}) => {
  return (
    <div className="ExploreSection" id={uid}>
      {sub_sections.map((item, i) => {
        return (
          <ExploreItem
            {...item}
            key={'ExploreSubSection--' + i}
            isMobile={isMobile}
          />
        );
      })}
    </div>
  );
};

const getData = async (url) => {
  const res = await axios.get(url);
  return res.data;
};

const ExploreSectionSwitch = (props) => {
  const { uid = 0, isMobile = false, activeCompare = [] } = props;
  const [data, setData] = useState(null);

  const itemsSlug = itemsSlugs(activeCompare);

  const dataurl =
    uid === 'compare' && activeCompare.length
      ? itemsSlug
      : `${apiurlpublic}explore/${uid + 1}.json`;

  useEffect(() => {
    if (uid === 'compare' && activeCompare.length) {
      fetchCompareItems(itemsSlug);
    } else {
      axios.get(dataurl).then((res) => {
        setData(res.data);
      });
    }
  }, [uid, activeCompare, dataurl]);

  const fetchCompareItems = async (urls) => {
    const itemsSlugsApi = urls?.split('-')?.map((item) => {
      return `${apiurlpublic}explore-word/${item}.json`;
    });
    let words = [];
    for (let i = 0; i < itemsSlugsApi.length; i++) {
      const data = await getData(itemsSlugsApi[i]);
      const newDataWordsArray = data?.words;
      words = [...words, ...newDataWordsArray];
    }
    const newData = { title: 'Individual Word', words };
    setData(newData);
  };

  // const { data } = useSWR([dataurl], multiFetcher);

  if (!data)
    return (
      <div className="ExploreSection ExploreSection--loading">Loading...</div>
    );

  if (uid === 'compare') {
    return (
      <div className="ExploreSection ExploreSection__YS" id={'explore-YS'}>
        <ExploreItem
          {...data}
          isMobile={isMobile}
          key={'explore--' + itemsSlug}
        />
      </div>
    );
  }

  return (
    <ExploreSection
      {...data}
      key={'Explore--' + uid}
      uid={'explore-' + uid}
      isMobile={isMobile}
    />
  );
};

const Explore = ({ data = [], isMobile = false }) => {
  const {
    intro = '',
    // explore = []
  } = data;
  const [activeID, setActiveID] = useState(0);
  const [activeMenu, setActiveMenu] = useState(false);

  const [activeCompare, setActiveCompare] = useState([]);

  const [ref, bounds] = useMeasure();

  const dataExplore = dataExploreJson ? dataExploreJson : [];

  // const { data: dataExplore = [] } = useSWR(`${apiurl}explore/`, fetcher);

  if (!dataExplore.length) return <div />;

  const items = dataExplore ? dataExplore : data;

  const goToTop = () => {
    const mainScroller = document.querySelector('.Explore__inner');
    mainScroller.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="Explore">
      <div className="Search__clear" onClick={(e) => goToTop()} />

      <div className="Explore__filter" ref={ref}>
        <ExploreFilter
          data={items}
          active={activeID}
          setActive={setActiveID}
          isMobile={isMobile}
          setActiveMenu={setActiveMenu}
          activeMenu={activeMenu}
          // bounds={bounds.height}
          setActiveCompare={setActiveCompare}
          activeCompare={activeCompare}
        />
      </div>
      <div className="Explore__inner">
        <div
          className="Explore__filter-space"
          style={{ height: bounds.height }}
        />
        {/* {} */}
        {intro && activeID === 0 ? (
          <div
            className="Explore__intro"
            dangerouslySetInnerHTML={{ __html: intro }}
          />
        ) : null}
        <ExploreSectionSwitch
          {...items[activeMenu]}
          isMobile={isMobile}
          key={activeID}
          uid={activeID}
          activeCompare={activeCompare}
        />
      </div>
    </div>
  );
};

export default Explore;
