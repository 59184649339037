import React, { useState } from 'react';
import { Button } from 'components/components';
// import { apiurl } from 'options';

// import useSWR from 'swr';
// import axios from 'axios';

// const fetcher = (url) => axios.get(url).then((res) => res.data);
import dataExplore from '../../data/explore-single.json';

const ExploreFilterCompare = ({
  setActiveCompare = undefined,
  activeCompare = [],
  onClickCompare = undefined,
  onClickCompareClose = undefined,
}) => {
  const [menu, setMenu] = useState([]);
  const [open, setOpen] = useState(false);

  // const { data: dataExplore = [] } = useSWR(
  //   `${apiurl}explore-single/`,
  //   fetcher
  // );

  const itemsSelect = dataExplore ? dataExplore : [];

  const addActive = (data) => {
    setMenu(false);
    setOpen(false);

    let newItems = [];

    const hasItem = activeCompare.filter((x) => x === data);

    if (hasItem.length) {
      newItems = activeCompare.filter((x) => x !== data);
    } else {
      newItems = [...activeCompare, data];
    }

    if (newItems.length === 6) {
      newItems.shift();
    }

    if (newItems.length === 0) {
      setMenu(0);
      onClickCompareClose();
      setActiveCompare([]);
    } else {
      onClickCompare();
      setActiveCompare(newItems);
    }
  };

  return (
    <div className={'ExploreFilter__compare' + (open ? ' is-open' : '')}>
      {open && (
        <div
          className="ExploreFilter__compare__back"
          onClick={(e) => {
            setOpen(false);
          }}
        />
      )}
      <div className="ExploreFilter__compare__items">
        <Button
          onClick={(e) => {
            setOpen(!open);
          }}
          text={'Select'}
        />
        <div className={'ExploreFilter__compare__items-inner'}>
          {!itemsSelect.length && (
            <div>
              <Button text={'Loading...'} theme={'reverse'} />
            </div>
          )}
          {itemsSelect.map((item, i) => (
            <div key={'ExploreFilter__compare--' + i}>
              <Button
                onClick={(e) =>
                  setMenu(item.title === menu.title ? false : item)
                }
                text={item.title}
                theme={'reverse'}
              />
              <ul className={item.title === menu.title ? 'is-active' : ''}>
                {item.words.map((item, indexTwo) => (
                  <li
                    key={'ExploreFilter__compare--' + i + '-' + indexTwo}
                    onClick={(e) => addActive(item)}
                  >
                    <Button onClick={(e) => addActive(item)} text={item.word} />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className="ExploreItem__Filter__selected">
        {activeCompare.map((item, i) => (
          <div key={'ExploreItem__Filter--' + i}>
            <Button
              onClick={(e) => addActive(item)}
              text={item.word}
              hasClose={true}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const ExploreFilter = ({
  data = [],
  active = 0,
  isMobile = false,
  // bounds = 120,
  setActiveMenu = undefined,
  setActive = undefined,
  activeMenu = false,
  setActiveCompare = undefined,
  activeCompare = [],
}) => {
  const onClick = (uid) => {
    setActive(uid);
    setActiveCompare([]);

    // console.log(uid);
    // const mainScroller = document.querySelector('.Explore__inner');

    // const top =
    //   document.querySelector('#' + uid).getBoundingClientRect().top +
    //   mainScroller.scrollTop;

    // mainScroller.scrollTo({
    //   top: top - bounds,
    //   behavior: 'smooth',
    // });
    // console.log(top, bounds);
  };

  const onClickCompare = () => {
    setActiveMenu(!activeMenu);
    setActive('compare');
    // const mainScroller = document.querySelector('.Explore__inner');
    // const top =
    //   document.querySelector('#explore-YS').getBoundingClientRect().top +
    //   mainScroller.scrollTop;
    // mainScroller.scrollTo({
    //   top: top - bounds,
    //   behavior: 'smooth'
    // });
  };

  const onClickCompareClose = () => {
    setActiveMenu(false);
    setActive(0);
    // const mainScroller = document.querySelector('.Explore__inner');
    // const top =
    //   document.querySelector('#explore-YS').getBoundingClientRect().top +
    //   mainScroller.scrollTop;
    // mainScroller.scrollTo({
    //   top: top - bounds,
    //   behavior: 'smooth'
    // });
  };

  const onMenu = (uid) => {
    if (!isMobile) return false;

    setActiveMenu(uid === activeMenu ? false : uid);
  };

  return (
    <div className={'ExploreFilter'}>
      <div className={'ExploreFilter__menuMobile'}>
        <div className={'ExploreFilter__menuMobile-item'}>
          <Button
            text={'Fixed category'}
            onClick={(e) => onMenu('category')}
            className="ExploreFilter__btn"
            theme={activeMenu === 'category' ? 'reverse' : false}
          />
        </div>
        <div className={'ExploreFilter__menuMobile-item'}>
          <Button
            text={'Individual word'}
            onClick={(e) => onMenu('word')}
            className="ExploreFilter__btn"
            theme={activeMenu === 'word' ? 'reverse' : false}
          />
        </div>
      </div>
      <div className={'ExploreFilter__menu'}>
        <ul className={activeMenu === 'category' ? ' is-active' : ''}>
          <li className="ExploreFilter__btnDesk">
            <Button
              text={'Choose a fixed category'}
              className="ExploreFilter__btn"
              theme={'explore'}
            />
          </li>
          {data.map((item, i) => {
            // const uid = i;
            return (
              <li key={'filterExplore--' + i}>
                <Button
                  text={item.title}
                  onClick={(e) => onClick(i)}
                  active={i === active ? true : false}
                />
              </li>
            );
          })}
        </ul>
      </div>
      <div className={'ExploreFilter__menu'}>
        <ul className={activeMenu === 'word' ? ' is-active' : ''}>
          <li className="ExploreFilter__btnDesk">
            <Button
              text={'Choose an individual word'}
              onClick={(e) => onMenu('word')}
              className="ExploreFilter__btn"
              theme={'explore'}
            />
          </li>
          <li key={'filterExplore--YS'}>
            <ExploreFilterCompare
              onClickCompare={onClickCompare}
              onClickCompareClose={onClickCompareClose}
              setActiveCompare={setActiveCompare}
              activeCompare={activeCompare}
              items={data}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ExploreFilter;
