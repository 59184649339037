import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ routes = [], activePath = false }) => {
  return (
    <div className={'Header'}>
      {routes.map(({ data = [], id = '', path = '' }, i) => {
        const { title = '' } = data;
        const active = activePath === id;
        return (
          <Link
            to={path}
            key={'Header--' + i}
            className={
              'Header__item' +
              (id ? ' Header__item--' + id : '') +
              (active ? ' is-active' : '')
            }
          >
            <span>{title}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default Header;
