import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import axios from 'axios';
// import { apiurl } from 'options';

import {
  Button,
  SearchForm,
  SearchItems,
  useNotification,
} from 'components/components';
import { getSearchApi } from 'utils/utils';

import dataRecentSearch from '../../data/recentsearch.json';

const Search = ({ data = {}, isMobile = false }) => {
  const showNotification = useNotification();
  const [recentSearched, setRecentSearched] = useState(
    dataRecentSearch?.recentsearch || []
  );
  const [mobileMenu, setMobileMenu] = useState('text');
  const [noWords, setNoWords] = useState([]);
  const [ignore, setIgnore] = useState(false);

  const [errorApp, setErrorApp] = useState(false);

  const [disabledNotice, setDisabledNotice] = useState(false);

  // useEffect(() => {
  //   if (!ignore) {
  //     axios
  //       .get(`${apiurl}recentsearch`)
  //       .then(function (response) {
  //         setRecentSearched(response.data['recentsearch']);
  //         setNoWords(response.data['nowords'].split(', '));
  //       })
  //       .catch(function (error) {
  //         // handle error
  //         // console.log(error);
  //       });

  //     setIgnore(true);
  //   }
  // }, [ignore]);

  const [apiIndex, setApiIndex] = useState(0);
  const { apiText, apiImage } = getSearchApi(apiIndex);

  const [searchValue, setSearchValue] = useState(false);
  //text
  const [searchText, setSearchText] = useState([]);
  const [searchTextInfo, setSearchTextInfo] = useState(false);
  const [searchTextLoading, setSearchTextLoading] = useState(false);
  //image
  const [searchImage, setSearchImage] = useState([]);
  const [searchImageInfo, setSearchImageInfo] = useState(false);
  const [searchImageLoading, setSearchImageLoading] = useState(false);

  const onSubmit = (data) => {
    const checkNoWord = noWords.includes(data.search.toLowerCase());
    if (checkNoWord) return false;

    makeSearch(data.search, true, true, false);
    showNotification('Search is disabled. Website has been discontinued.');
    // addToRecentSearch(data.search);
  };

  // const addToRecentSearch = (value) => {
  //   setRecentSearched((recentSearched) => [value, ...recentSearched]);

  //   var searchData = new FormData();
  //   searchData.append('word', value);

  //   var config = {
  //     method: 'post',
  //     url: `${apiurl}search`,
  //     headers: {
  //       Authorization: 'Basic b29ydDpuNnREIHBPSmUgTHdDWSBBNWh6IFFwZ2UgZUNqcw==',
  //     },
  //     data: searchData,
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       // console.log(JSON.stringify(response.data));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  useEffect(() => {
    if (apiIndex && searchValue && apiIndex !== 16) {
      const sameYes = searchText.length && searchImage.length ? true : false;
      makeSearch(searchValue, true, true, sameYes);
    }
    if (apiIndex === 16) {
      setErrorApp('Daily limit exceeded');
    }
  }, [apiIndex]);

  const makeSearch = (value, text, Image, loadMore) => {
    showNotification('Search is disabled. Website has been discontinued.');
    return false;
    const valueItem = value ? value : searchValue;
    const same = loadMore ? true : false;

    if (!same) {
      setSearchValue(value);
    }

    let requests = [];
    let requestType = [];

    if (text) {
      setSearchTextLoading(true);
      const start = same ? searchText.length + 1 : 0;
      const textRequest = axios.get(
        apiText + '&q=' + valueItem + '&start=' + start
      );
      requestType.push('text');
      requests.push(textRequest);
    }
    if (Image) {
      setSearchImageLoading(true);
      const start = same ? searchImage.length + 1 : 0;
      const imgRequest = axios.get(
        apiImage + '&q=' + valueItem + '&start=' + start
      );
      requestType.push('image');
      requests.push(imgRequest);
    }

    axios
      .all(requests)
      .then(
        axios.spread((...responses) => {
          for (let i = 0; i < requestType.length; i++) {
            const type = requestType[i];
            if (type === 'image') {
              setSearchImageLoading(false);
              if (same) {
                setSearchImage(searchImage.concat(responses[i].data.items));
              } else {
                setSearchImageInfo(responses[i].data.searchInformation);
                setSearchImage(responses[i].data.items);
              }
            } else {
              setSearchTextLoading(false);
              if (same) {
                setSearchText(searchText.concat(responses[i].data.items));
              } else {
                setSearchTextInfo(responses[i].data.searchInformation);
                setSearchText(responses[i].data.items);
              }
            }
          }
        })
      )
      .catch((errors) => {
        // react on errors.
        setApiIndex(apiIndex + 1);
        // console.warn('errors:', errors);
      });
  };

  const clearSearch = (data) => {
    setSearchValue(false);
    // text
    setSearchText([]);
    setSearchTextInfo(false);
    setSearchTextLoading(false);
    //image
    setSearchImage([]);
    setSearchImageInfo(false);
    setSearchImageLoading(false);
    //input
    document.querySelector('.SearchForm__input--main input').value = '';
  };

  const searchStyles = useSpring({
    top: !isMobile && searchValue ? '0%' : '50%',
    right: !isMobile && searchValue ? '0%' : '50%',
    transform:
      !isMobile && searchValue ? 'translate(0%, 0%)' : 'translate(50%, -50%)',
    opacity: isMobile && searchValue ? '0' : '1',
  });

  return (
    <div className="Search">
      <div className="Search__clear" onClick={(e) => clearSearch()} />
      <animated.div className={'Search__main'} style={searchStyles}>
        <SearchForm
          onSubmit={onSubmit}
          searchValue={searchValue}
          placeholderArray={['Typing...']}
          showLabel={true}
          hideSubmit={searchValue ? true : false}
        />
        {searchValue && (
          <div className="SearchForm__close" onClick={(e) => clearSearch()} />
        )}
        {errorApp && <div className="errorApp">{errorApp}</div>}
      </animated.div>

      {searchValue && (
        <div className={'Search__inner active--' + mobileMenu}>
          <div className="Search__mobile-menu">
            <Button
              text="Text Results"
              active={mobileMenu === 'text' ? true : false}
              onClick={() => setMobileMenu('text')}
            />
            <Button
              text="Image Results"
              active={mobileMenu === 'image' ? true : false}
              onClick={() => setMobileMenu('image')}
            />
            <div className="Button Search__mobile-close">
              <div
                className="SearchForm__close"
                onClick={(e) => clearSearch()}
              />
            </div>
          </div>
          <SearchItems
            type={'text'}
            searchValue={searchValue}
            items={searchText}
            info={searchTextInfo}
            isLoading={searchTextLoading}
            loadMore={(e) => makeSearch(false, true, false, true)}
          />
          <SearchItems
            type={'image'}
            searchValue={searchValue}
            items={searchImage}
            info={searchImageInfo}
            isLoading={searchImageLoading}
            loadMore={(e) => makeSearch(false, false, true, true)}
          />
        </div>
      )}

      {!searchValue && recentSearched.length ? (
        <div className="Search__recent">
          <h2>Recently searched:</h2>
          <ul>
            {recentSearched.map(
              (item, i) =>
                i <= (isMobile ? 6 : 20) && (
                  <li key={'recentSearch--' + i}>
                    <Button
                      onClick={(e) => makeSearch(item, true, true, false)}
                      to="/"
                      text={item}
                    />
                  </li>
                )
            )}
          </ul>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Search;
