const useNotification = () => {
  const showNotification = (text = '') => {
    const notificationDiv = document.querySelector('.notification');
    const notification = document.createElement('div');
    notification.classList.add('notification-item');
    notification.textContent = text;
    const close = document.createElement('div');
    close.classList.add('notification-close');
    close.onclick = () => {
      notification.remove();
    };
    notification.appendChild(close);
    notificationDiv.appendChild(notification);
    setTimeout(() => {
      if (notification) {
        notification.remove();
      }
    }, 2000);
  };

  return showNotification;
};

export default useNotification;
