import React, { useState, useRef } from 'react';
import { ChartLines, ChartPie } from 'components/components';
import DatePicker from 'react-datepicker';
import { filterWords, createPdf, filterTracking } from 'utils/utils';
import moment from 'moment';

const ExploreItemCharts = ({
  title = '',
  width,
  words = false,
  isMobile = false,
  active = null,
  children,
}) => {
  const wordsFilter = filterWords(words, active);

  const samedate =
    wordsFilter[0] &&
    wordsFilter[0].trackingFiltered &&
    wordsFilter[0].trackingFiltered.length === 1
      ? true
      : false;

  return (
    <>
      {!samedate && (
        <div className="ExploreItem__chart">
          <ChartLines
            data={wordsFilter}
            isMobile={isMobile}
            activeFilter={active ? true : false}
          />
        </div>
      )}
      {children}
      <div className="ExploreItem__pie">
        <ChartPie
          width={(width - 20) / 3.5}
          data={wordsFilter}
          val="%"
          activeFilter={active ? true : false}
        />
      </div>
    </>
  );
};

const ExploreItem = ({
  title = '',
  width,
  words = false,
  isMobile = false,
}) => {
  const ref = useRef(null);
  const firstTrack = filterTracking(words[0].tracking);
  const dateStart = new Date(firstTrack[0].date);
  const dateEnd = new Date(firstTrack[firstTrack.length - 1].date);

  const [open, setOpen] = useState(false);

  const [startDate, setStartDate] = useState(dateStart);
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="ExploreItem" ref={ref}>
      <div className="ExploreItem__text">
        <h2>{title}</h2>
      </div>

      {words.length && (
        <ExploreItemCharts
          words={words}
          isMobile={isMobile}
          width={width}
          active={startDate && endDate ? [startDate, endDate] : false}
          key={'exploreitem--' + words.length}
        >
          <div className="ExploreItem__nav">
            <ul>
              <li>
                <div
                  className="Button Button--explore"
                  onClick={(e) => setOpen(!open)}
                >
                  {startDate && endDate
                    ? `${moment(startDate).format('MMM D YYYY')} — ${moment(
                        endDate
                      ).format('MMM D YYYY')} `
                    : 'Select a date range'}
                </div>
                <div
                  className={`ExploreItem__datepicker ${open ? 'is-open' : ''}`}
                >
                  <DatePicker
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={dateStart}
                    maxDate={dateEnd}
                    selectsRange
                    inline
                  />
                </div>
              </li>
              <li>
                <div
                  className="Button"
                  onClick={(e) =>
                    createPdf(
                      words,
                      startDate && endDate ? [startDate, endDate] : false,
                      ref
                    )
                  }
                >
                  Download PDF
                </div>
              </li>
            </ul>
          </div>
        </ExploreItemCharts>
      )}
    </div>
  );
};

export default ExploreItem;
