import React from 'react';

const Button = ({
  onClick = undefined,
  text = false,
  className = '',
  theme = false,
  active = false,
  hasClose = false,
}) => {
  return (
    <div
      onClick={onClick}
      className={
        'Button' +
        (theme ? ' Button--' + theme : '') +
        (active ? ' is-active' : '') +
        ' ' +
        className
      }
    >
      <span>{text}</span>
      {hasClose && <div className="Button--close" />}
    </div>
  );
};

export default Button;
