import React from 'react';
import { Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import useMeasure from 'react-use-measure';

import { About, Search, Explore, Compare, NotFound } from 'views/views';
import { TextSlider, Header, Textures } from 'components/components';
import { getCurrentActive, getItem } from 'utils/utils';

const LayoutInner = (props) => {
  switch (props.id) {
    case 'about':
      return <About {...props} />;
    case 'search':
      return <Search {...props} />;
    case 'explore':
      return <Explore {...props} />;
    case 'compare':
      return <Compare {...props} />;
    case 'notFound':
      return <NotFound />;
    default:
      return <div />;
  }
};

const Nav = ({ title = '', desc = false, active = false }) => {
  const stylesOpacity = useSpring({ opacity: active ? 1 : 0 });
  return (
    <div className="Layout__nav">
      <h1 className="Layout__nav-title">{title}</h1>
      {desc && (
        <animated.div style={stylesOpacity} className="Layout__nav-desc">
          <h2>
            <TextSlider text={desc} />
          </h2>
        </animated.div>
      )}
    </div>
  );
};

const LayoutItem = ({
  active = false,
  item = {},
  index = 0,
  currentActive = 0,
  width = window.innerWidth,
  isMobile = false,
}) => {
  const { data = [] } = item;
  const { desc = '', title = '' } = data;

  const total = (currentActive < index ? width - 440 : 0) + index * 110;
  const styles = useSpring({ left: isMobile ? 0 : total });
  const stylesOpacity = useSpring({ opacity: active ? 1 : 0 });

  return (
    <animated.div
      className={
        'Layout__item' +
        (item.id && ' Layout__item--' + item.id) +
        (active ? ' is-active' : '')
      }
      style={styles}
    >
      {!active && <Link className="Layout__link" to={item.path} />}
      {title && <Nav title={title} desc={desc} active={active} />}

      <animated.div className="Layout__inner" style={stylesOpacity}>
        <LayoutInner
          {...item}
          active={active}
          width={width}
          isMobile={isMobile}
        />
      </animated.div>
    </animated.div>
  );
};

const Layout = ({ data = false, location = false }) => {
  const { pathname } = location;
  const [ref, bounds] = useMeasure();
  const activePath = !pathname.split('/')[1]
    ? 'search'
    : pathname.split('/')[1];

  const routes = [
    {
      id: 'search',
      path: '/',
      data: getItem('search', data),
    },
    {
      id: 'compare',
      path: '/compare',
      data: getItem('compare', data),
    },
    {
      id: 'explore',
      path: '/explore',
      data: getItem('explore', data),
    },

    {
      id: 'about',
      path: '/about',
      data: getItem('about', data),
    },
  ];

  const currentActive = getCurrentActive(activePath, routes);

  return (
    <div className="Layout" ref={ref}>
      <Header routes={routes} activePath={activePath} />
      <Textures />

      {currentActive === false ? (
        <LayoutItem
          key={'Layout--notfound'}
          active={true}
          item={{
            title: '404',
            id: 'notFound',
          }}
        />
      ) : !data ? (
        <div></div>
      ) : (
        routes.map((item, i) => {
          const active = activePath === item.id;
          return (
            <LayoutItem
              key={'Layout--' + i}
              item={item}
              active={active}
              currentActive={currentActive}
              index={i}
              width={bounds.width}
              isMobile={bounds.width <= 992 ? true : false}
            />
          );
        })
      )}
    </div>
  );
};

export default Layout;
