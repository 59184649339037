import React from 'react';

export const svgLibrary = {
  search: {
    viewBox: `0 0 15 15`,
    inner: (
      <g>
        <path d="m10.3 8.5c.4-.8.7-1.6.7-2.6 0-3-2.4-5.5-5.5-5.5-3.1.1-5.5 2.5-5.5 5.6 0 3 2.4 5.5 5.5 5.5 1.4 0 2.8-.6 3.7-1.5l4.6 4.6 1.2-1.2zm-8.6-2.5c0-2.1 1.7-3.7 3.7-3.7s3.8 1.6 3.8 3.7-1.7 3.7-3.7 3.7-3.8-1.7-3.8-3.7z" />
      </g>
    ),
  },
};
