import React, { useState } from 'react';
import {
  LineSeries,
  FlexibleXYPlot,
  XAxis,
  YAxis,
  // Crosshair,
} from 'react-vis';
import { timeFormatLocale } from 'd3-time-format';
import * as d3 from 'd3';
import { useInView } from 'react-intersection-observer';

const ChartLegend = ({
  items = [],
  setActive = undefined,
  active = false,
  isMobile = false,
}) => {
  const type = isMobile ? 'horizontal' : 'vertical';

  return (
    <div className={'rv-discrete-color-legend ' + type}>
      {items.map((item, i) => {
        return (
          <div
            className={'rv-discrete-color-legend-item ' + type}
            key={'chart-legend--' + i}
            onMouseEnter={(e) => setActive(i)}
            onMouseLeave={(e) => setActive(false)}
            style={{
              opacity: active === false ? 1 : active === i ? 1 : 0.3,
              cursor: 'pointer',
            }}
          >
            <svg
              className="rv-discrete-color-legend-item__color"
              height="2"
              width="30"
            >
              <path
                className="rv-discrete-color-legend-item__color__path"
                d="M 0, 1 L 30, 1"
                style={{
                  stroke: item.color,
                  strokeDasharray: item.strokeDasharray,
                }}
              ></path>
            </svg>
            <span className="rv-discrete-color-legend-item__title">
              {item.title}
            </span>
          </div>
        );
      })}
    </div>
  );
};
const locale = timeFormatLocale({
  dateTime: '%a %e %b %X %Y',
  date: '%d/%m/%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
});

// const formatMillisecond = locale.format('.%L'),
//   formatSecond = locale.format(':%S'),
//   formatMinute = locale.format(':%M:%S'),
//   formatHour = locale.format('%H:%M:%S %p'),
//   formatDay = locale.format('%d'),
//   formatWeek = locale.format('%b %d'),
//   formatMonth = locale.format('%B'),
//   formatYear = locale.format('%Y');
const formatMillisecond = locale.format(''),
  formatSecond = locale.format(''),
  formatMinute = locale.format(''),
  formatHour = locale.format(''),
  formatDay = locale.format('%d'),
  formatWeek = locale.format('%b %d'),
  formatMonth = locale.format('%B'),
  formatYear = locale.format('%Y');

// 10 styles

const ChartLines = ({ data = [], isMobile = false, activeFilter = false }) => {
  const styles = [
    {
      stroke: '#000',
      strokeDasharray: '',
    },
    {
      stroke: '#000',
      strokeDasharray: '2,2',
    },
    {
      stroke: '#000',
      strokeDasharray: '10,5,2,2,2,5',
    },
    {
      stroke: '#000',
      strokeDasharray: '5,5',
    },
    {
      stroke: '#000',
      strokeDasharray: '5,10',
    },
    {
      stroke: '#000',
      strokeDasharray: '5,1',
    },
    {
      stroke: '#000',
      strokeDasharray: '1,5',
    },
    {
      stroke: '#000',
      strokeDasharray: '0.9',
    },
    {
      stroke: '#000',
      strokeDasharray: '15,10,5',
    },
    {
      stroke: '#000',
      strokeDasharray: '5,5,1,5',
    },
    {
      stroke: '#000',
      strokeDasharray: '10,5',
    },
    {
      stroke: '#000',
      strokeDasharray: '15,10,5',
    },
  ];

  const Legend = data.map((d, i) => {
    return {
      title: d.title,
      color: styles[i].stroke,
      strokeDasharray: styles[i].strokeDasharray,
    };
  });

  const [active, setActive] = useState(false);

  const [inViewRef, inView] = useInView({
    triggerOnce: false,
    rootMargin: '0px 0px',
  });

  return (
    <div className="ChartLines">
      <div className="ChartLines__legend-img">
        <div className="ChartLines__legend ChartLines__legend--pdf">
          <ChartLegend
            items={Legend}
            isMobile={isMobile}
            setActive={setActive}
            active={active}
          />
        </div>
      </div>
      <div className="ChartLines__wrap">
        <div className="ChartLines__legend">
          <ChartLegend
            items={Legend}
            isMobile={isMobile}
            setActive={setActive}
            active={active}
          />
        </div>

        <div className="ChartLines__main">
          <h3>Frequency of use</h3>
          <div className="ChartLines__inner" ref={inViewRef}>
            {inView && (
              <FlexibleXYPlot xType="time">
                <XAxis
                  tickLabelAngle={-45}
                  tickFormat={function multiFormat(date) {
                    return (
                      d3.timeSecond(date) < date
                        ? formatMillisecond
                        : d3.timeMinute(date) < date
                        ? formatSecond
                        : d3.timeHour(date) < date
                        ? formatMinute
                        : d3.timeDay(date) < date
                        ? formatHour
                        : d3.timeMonth(date) < date
                        ? d3.timeWeek(date) < date
                          ? formatDay
                          : formatWeek
                        : d3.timeYear(date) < date
                        ? formatMonth
                        : formatYear
                    )(date);
                  }}
                />
                <YAxis
                  tickFormat={function myFormatter(t, i) {
                    const nr = t.toString();
                    const total = nr.length;
                    const number =
                      total > 6
                        ? nr.slice(0, -6) + 'm'
                        : total > 3
                        ? nr.slice(0, -3) + 'k'
                        : nr;
                    return number;
                  }}
                />
                {data.map((item, i) => {
                  const track = activeFilter
                    ? item.trackingFiltered
                    : item.tracking;

                  // console.log('track', track);

                  const dataOne = track.map((d) => {
                    const time = new Date(d.date).getTime();
                    return {
                      x: time,
                      y: d.total * 1,
                    };
                  });

                  return (
                    <LineSeries
                      curve={'curveBasis'}
                      data={dataOne}
                      key={'ChartItem--' + i}
                      opacity={active === false ? 1 : active === i ? 1 : 0.3}
                      strokeStyle="solid"
                      style={styles[i]}
                    />
                  );
                })}
              </FlexibleXYPlot>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartLines;
