import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';

const CompareForm = ({ onSubmit = undefined }) => {
  const placeholder = 'Typing...';
  const refContainer = useRef(null);

  const { register, handleSubmit } = useForm({
    submitFocusError: true,
  });

  const enterClick = (e) => {
    if (e.which === 13) {
      refContainer.current.click();
    }
  };

  return (
    <form className="CompareForm" onSubmit={handleSubmit(onSubmit)}>
      <div className="CompareForm__input">
        <div className={'SearchForm__input'}>
          <input
            name={'compareOne'}
            id="compareOne"
            type={'text'}
            placeholder={placeholder}
            ref={register({ required: true, min: 3 })}
            onKeyDown={enterClick}
          />
        </div>
      </div>
      <div className="CompareForm__mid">vs.</div>
      <div className="CompareForm__input">
        <div className={'SearchForm__input'}>
          <input
            name={'compareTwo'}
            id="compareTwo"
            type={'text'}
            placeholder={placeholder}
            ref={register({ required: true, min: 3 })}
            onKeyDown={enterClick}
          />
        </div>
      </div>
      <div className={'SearchForm__button'}>
        <button
          className="Button Button--search"
          type="submit"
          ref={refContainer}
        >
          Compare
        </button>
      </div>
    </form>
  );
};

export default CompareForm;
