import React, { useState } from 'react';
import { averageChartPie, chartPieValues, formatNr } from 'utils/utils';
import { RadialChart, Hint } from 'react-vis';
import useMeasure from 'react-use-measure';
import { useInView } from 'react-intersection-observer';

const ChartLegend = ({
  items = [],
  setActive = undefined,
  active = false,
  isMobile = false
}) => {
  const type = isMobile ? 'horizontal' : 'vertical';
  return (
    <div className={'rv-discrete-color-legend ' + type}>
      {items.map((item, i) => {
        return (
          <div
            className={'rv-discrete-color-legend-item ' + type}
            key={'chart-legend--' + i}
            onMouseEnter={e =>
              setActive({
                ...item,
                ...{ angle: 0, angle0: 0, radius: 1, radius0: 0, x: 0, y: 0 }
              })
            }
            onMouseLeave={e => setActive(false)}
            style={{
              opacity:
                active === false
                  ? 1
                  : active.indexVal === item.indexVal
                  ? 1
                  : 0.3,
              cursor: 'pointer'
            }}
          >
            <svg
              className="rv-discrete-color-legend-item__color"
              height="2"
              width="30"
            >
              <path
                className={
                  'rv-discrete-color-legend-item__color__path ' +
                  item.className +
                  '--stroke'
                }
                d="M 0, 0 L 30, 0"
                style={{
                  strokeWidth: '20px'
                }}
              ></path>
            </svg>
            <span className="rv-discrete-color-legend-item__title">
              {item.label}
            </span>
          </div>
        );
      })}
    </div>
  );
};

const ChartPie = ({
  data = [],
  val = '',
  compareChart = false,
  isMobile = false,
  activeFilter = false
}) => {
  const [value, setValue] = useState(false);
  const [ref, bounds] = useMeasure();

  const dataIn = compareChart
    ? chartPieValues(data)
    : averageChartPie(data, activeFilter);

  const width = bounds.width ? bounds.width : 300;

  const [inViewRef, inView] = useInView({
    triggerOnce: false,
    rootMargin: '0px 0px'
  });

  return (
    <div className={'ChartPie' + (compareChart ? ' ChartPie--compare' : '')}>
      <div className="ChartPie__main">
        <h3>Ratio</h3>
        <div
          className={
            'ChartPie__inner' +
            (value ? ' has-value value--' + value.indexVal : '')
          }
        >
          <div className="ChartPie__content" ref={ref}>
            <div className="ChartPie__chart" ref={inViewRef}>
              {inView && (
                <RadialChart
                  className={'donut-chart-example'}
                  getAngle={d => d.value}
                  data={dataIn}
                  onValueMouseOver={v => setValue(v)}
                  onSeriesMouseOut={() => setValue(false)}
                  width={width}
                  height={width}
                  stroke="#000"
                >
                  {value !== false && (
                    <Hint value={value}>
                      <div className="ChartPie__hint">
                        <div className="ChartPie__hint-inner">
                          {value.label} {value.value}% ({formatNr(value.total)})
                        </div>
                      </div>
                    </Hint>
                  )}
                </RadialChart>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="ChartPie__legend">
        <ChartLegend
          items={dataIn}
          isMobile={isMobile}
          setActive={setValue}
          active={value}
        />
      </div>
    </div>
  );
};

export default ChartPie;
