import { filterWords } from 'utils/utils';
import pdfMake from 'pdfmake/build/pdfmake';
import moment from 'moment';
import { toPng } from 'html-to-image';

export function tableBody(array, active) {
  let data = [];
  let head = [];
  let current = false;

  for (let i = 0; i < array.length; i++) {
    const el = array[i];

    // let items = [];

    if (i === 0) {
      head.push({ text: '', border: [false, true, false, false] });
    }
    head.push({
      text: el.title,
      style: 'tableTop',
      border: [false, true, false, false],
    });
  }

  data.push(head);

  const firstEl = array[0];

  const track = active ? firstEl.trackingFiltered : firstEl.tracking;

  for (let iTrack = 0; iTrack < track.length; iTrack++) {
    const el = track[iTrack];
    const date = el.date.slice(0, -3);
    const day = el.date.split('/')[2];
    let item = [];
    let totals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    item.push(day);

    for (let iItem = 0; iItem < array.length; iItem++) {
      const el = array[iItem];
      const trackEl = active
        ? el.trackingFiltered[iTrack]
        : el.tracking[iTrack];
      item.push(trackEl.total);
      totals[iItem] += parseInt(trackEl.total);
    }

    //

    if (date !== current) {
      let itemHead = [];
      current = date;
      itemHead.push({ text: date, style: 'tableHeader' });

      for (let iItem = 0; iItem < array.length; iItem++) {
        itemHead.push({ text: totals[iItem], style: 'tableHeader' });
      }

      data.push(itemHead);
    }

    data.push(item);
  }

  // console.log(data);

  // [
  //   [
  //     { text: '', border: [false, true, false, false] },
  //     {
  //       text: 'Activism',
  //       style: 'tableTop',
  //       border: [false, true, false, false]
  //     },
  //     {
  //       text: 'Breathtaking',
  //       style: 'tableTop',
  //       border: [false, true, false, false]
  //     }
  //   ],
  //   [
  //     { text: 'Header 1', style: 'tableHeader' },
  //     { text: 'Header 2', style: 'tableHeader' },
  //     { text: 'Header 3', style: 'tableHeader' }
  //   ],
  //   ['Sample value 1', 'Sample value 2', 'Sample value 3'],
  //   ['Sample value 1', 'Sample value 2', 'Sample value 3'],
  //   ['Sample value 1', 'Sample value 2', 'Sample value 3'],
  //   ['Sample value 1', 'Sample value 2', 'Sample value 3']
  // ]
  return data;
}

export function createPdf(array, active, item) {
  const dataWords = filterWords(array, active);

  const chart = item.current.querySelector('.ChartLines__inner');
  const legend = item.current.querySelector(
    '.ChartLines__legend--pdf .rv-discrete-color-legend'
  );
  const title = item.current.querySelector('.ExploreItem__text').textContent;

  let widthsTable = ['*'];

  for (let i = 0; i < array.length; i++) {
    widthsTable.push('*');
  }

  let chartUrl = false;
  let legendUrl = false;

  if (chart) {
    chart.parentNode.classList.add('--pdfcreate');
    toPng(chart).then(function (dataUrl) {
      chartUrl = dataUrl;
      toPng(legend).then(function (dataUrl) {
        legendUrl = dataUrl;
        chart.parentNode.classList.remove('--pdfcreate');
        downloadPdf(dataWords, widthsTable, chartUrl, legendUrl, title, active);
      });
    });
  } else {
    downloadPdf(dataWords, widthsTable, false, false, title, active);
  }

  return false;

  // return data;
}

function downloadPdf(
  dataWords,
  widthsTable,
  chartImg,
  legendImg,
  title,
  active
) {
  pdfMake.fonts = {
    Roboto: {
      normal:
        'https://oort.ams3.digitaloceanspaces.com/wordsonfashionwebsites/Jungka-Regular.ttf',
      bold: 'https://oort.ams3.digitaloceanspaces.com/wordsonfashionwebsites/Jungka-Regular.ttf',
      italics:
        'https://oort.ams3.digitaloceanspaces.com/wordsonfashionwebsites/Jungka-Regular.ttf',
      bolditalics:
        'https://oort.ams3.digitaloceanspaces.com/wordsonfashionwebsites/Jungka-Regular.ttf',
    },
  };

  const dateFrom = active[0] ? moment(active[0]).format('DD/MM/YYYY') : false;
  const dateTo = active[1] ? moment(active[1]).format('DD/MM/YYYY') : false;

  const date = moment().format('DD/MM/YYYY');
  const dateTwo = moment().format('DD-MM-YYYY');

  const mainText =
    dateFrom && dateTo
      ? 'wordsonfashionwebsites.com\n\nFrequency of use between ' +
        dateFrom +
        ' - ' +
        dateTo +
        '\n' +
        title +
        '\n\n'
      : 'wordsonfashionwebsites.com\n\n' + title + '\n\n';

  const textInfo =
    'wordsonfashionwebsites explores the use of text in the realm of online fashion media. The website is connected to a selection of 10 high-profile fashion/lifestyle websites (of which some have over 90 million unique monthly visitors and a large commercial impact) and offers the option to search, track and compare the use of text on these websites.';

  const textSources =
    'businessoffashion.com - net-a-porter.com - thecut.com - hypebeast.com - highsnobiety.com - harpersbazaar.com - refinery29.com - elle.com - vogue.com - asos.com \n\n';

  const contentMain = [
    { text: 'TABLE', style: 'smText', pageBreak: 'before' },
    {
      style: 'tableExample',

      table: {
        widths: widthsTable,
        headerRows: 2,
        body: tableBody(dataWords, active),
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 ? 0 : 1;
          // return 1;
        },
        vLineWidth: function (i, node) {
          return 1;
        },
        hLineColor: function (i, node) {
          // 	return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
        },
        vLineColor: function (i, node) {
          // 	return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
        },
        hLineStyle: function (i, node) {
          return { dash: { length: 1, space: 1 } };
        },
        vLineStyle: function (i, node) {
          return { dash: { length: 1, space: 1 } };
        },
        // paddingLeft: function(i, node) { return 4; },
        // paddingRight: function(i, node) { return 4; },
        // paddingTop: function(i, node) { return 2; },
        // paddingBottom: function(i, node) { return 2; },
        fillColor: function (rowIndex, node, columnIndex) {
          // return rowIndex === 1 ? 'black' : null;
          return null;
        },
      },
    },
  ];
  const contentIntro =
    legendImg && chartImg
      ? [
          {
            text: mainText,
            style: 'largeText',
          },
          {
            text: textInfo,
            style: 'largeText',
          },
          { text: '\n\nSOURCES', style: 'smText' },
          {
            text: textSources,
            style: 'largeText',
          },
          {
            // if you specify width, image will scale proportionally
            image: legendImg,
            width: 500,
          },
          {
            // if you specify width, image will scale proportionally
            image: chartImg,
            width: 500,
          },
        ]
      : [
          {
            text: mainText,
            style: 'largeText',
          },
          {
            text: textInfo,
            style: 'largeText',
          },
          { text: '\n\nSOURCES', style: 'smText' },
          {
            text: textSources,
            style: 'largeText',
          },
        ];

  const fullContent = [...contentIntro, ...contentMain];

  var dd = {
    info: {
      title: 'wordsonfashionwebsites.com',
      author: 'Femke de Vries',
    },
    pageSize: 'A4',
    pageMargins: [40, 40, 40, 60],
    footer: function (currentPage, pageCount) {
      return {
        table: {
          widths: '*',
          body: [
            [
              {
                text:
                  'THIS PDF WAS DOWNLOADED ON ' +
                  date +
                  ' FROM WWW.WORDSONFASHIONWEBSITES.COM*',
                fontSize: 8,
                alignment: 'center',
                margin: [0, 0, 0, 0],
              },
            ],
            [
              {
                text: currentPage.toString() + '/' + pageCount,
                fontSize: 8,
                alignment: 'center',
                margin: [0, 0, 0, 0],
              },
            ],
          ],
        },
        layout: 'noBorders',
      };
    },

    content: fullContent,
    styles: {
      header: {
        fontSize: 12,
        bold: false,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 12,
        bold: false,
        margin: [0, 10, 0, 5],
      },
      tableExample: {
        margin: [0, 0, 0, 0],
        // 			fillColor: '#ccc',
      },
      tableHeader: {
        background: 'black',
        bold: false,
        // 			fontSize: 13,
        fontSize: 8,
        color: 'white',
        // 			alignment: 'center'
      },
      tableTop: {
        //  background: 'black',
        // 			bold: false,
        fontSize: 8,
        color: 'black',
        // 			alignment: 'center'
      },
      smText: {
        //  background: 'black',
        bold: false,
        fontSize: 8,
        color: 'black',
        // 			alignment: 'center'
      },
      largeText: {
        //  background: 'black',
        bold: false,
        fontSize: 12,
        color: 'black',
        // 			alignment: 'center'
      },
    },
    defaultStyle: {
      fontSize: 8,
      // alignment: 'justify'
    },
  };

  pdfMake.createPdf(dd).download('wordsonfashionwebsites--' + dateTwo);
}
