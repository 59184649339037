import React from 'react';
import { Image } from 'components/components';

const ItemInner = (props) => {
  const { link = '', type = false } = props;

  switch (type) {
    case 'image':
      const { image = '' } = props;
      const { width = 0, height = 0, contextLink = '' } = image;
      return (
        <a
          className={'Item Item--image'}
          href={contextLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            className="Item__img"
            width={width}
            height={height}
            src={link}
            maxRatio={1}
          />
        </a>
      );
    default:
      const {
        // htmlSnippet = '',
        snippet = '',
        htmlTitle = '',
        pagemap = false,
      } = props;
      const img = pagemap.cse_image ? pagemap.cse_image[0] : false;

      return (
        <a
          className={'Item Item--text'}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className="Item__title"
            dangerouslySetInnerHTML={{ __html: htmlTitle }}
          />
          <div className="Item__link">{link}</div>
          {img && <Image className="Item__img" {...img} maxRatio={1} />}
          <div
            className="Item__desc"
            dangerouslySetInnerHTML={{
              __html: snippet,
            }}
          />
        </a>
      );
  }
};

const Item = (props) => {
  return <ItemInner {...props} />;
};

export default Item;
