import React from 'react';

const About = ({ data = {} }) => {
  const { main = false, side = false } = data;
  return (
    <div className="About">
      <div
        className="About__inner About__main"
        dangerouslySetInnerHTML={{ __html: main }}
      />
      {side && (
        <div
          className="About__inner About__sidebar"
          dangerouslySetInnerHTML={{ __html: side }}
        />
      )}
    </div>
  );
};

export default About;
