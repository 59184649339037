import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

// import useSWR from 'swr';
// import axios from 'axios';
import { Layout } from 'views/views';
// import { apiurl } from 'options';

// const fetcher = (url) => axios.get(url).then((res) => res.data);

// const title = 'Words on Fashion Websites';
import dataPages from 'data/pages.json';

function App() {
  const data = dataPages;
  const [alert, setAlert] = useState(true);

  // const { data } = useSWR(`${apiurl}pages`, fetcher);
  // const data = {};

  return (
    <Router>
      <Switch>
        <Redirect from="/search" to="/" />
        <Route
          render={(routeProps) => <Layout {...routeProps} data={data} />}
        />
      </Switch>
      <div className="notification">
        {alert && (
          <div className="notification-item">
            Because the main api to get the website working is getting
            discontinued, the website is at the moment inactive and functions as
            an archive. Contact us for questions and recommendations.
            <div
              className="notification-close"
              onClick={() => setAlert(false)}
            />
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
